import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import Select, { ActionMeta, MultiValue, PropsValue, StylesConfig } from "react-select";
import { getAuthToken, request } from '../helpers/axios_helper';
import Header from "../Header/Header";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PulseLoader } from "react-spinners";


interface ListItem {
    id: string;
    numero: string;
    editeur: string;
    base: string;
    application: string;
    description: string;
    accueil: string;
    pathAccueil: string;
    pub: string;
    pathPub: string;
    infoEditeurs: string;
    pathInfoEditeurs: string;
    infos: string;

}


interface Option {
    value: any;
    label: any;
}



interface CustomStyles extends StylesConfig<Option, true> {
    // Définissez vos propres types de styles ici si nécessaire
}

const MultipleNum: React.FC = () => {
    const [selectedOptions, setSelectedOptions] = useState<PropsValue<Option>>([]);
    const [options, setOptions] = useState<Option[]>([])
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [data, setData] = useState<ListItem[]>([]);
    const [filteredData, setFilteredData] = useState<ListItem[]>([]);
    const [service, setService] = useState("");
    const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
    const [categorie, setCategorie] = useState("");
    const [urlEndPoint, setUrlEndPoint] = useState("");
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        // Fonction pour effectuer la requête GET avec Axios
        const fetchData = async () => {
            setIsLoading(true); // Démarrez l'indicateur de chargement au début de la requête
            try {
                const token = getAuthToken(); // Récupérer le token du local storage
                const headers = token ? { 'Authorization': `Bearer ${token}` } : {}; // Préparer les headers avec le token (s'il existe)
                const response = await request("GET", "/numeroDetail/getliste", headers); // Utiliser la fonction request de axios_helper.ts
                setData(response.data.listeNumeroDetail)
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
            } finally {
                setIsLoading(false); // Arrêtez l'indicateur de chargement à la fin de la requête
            }
        };

        fetchData(); // Appeler la fonction fetchData pour récupérer les données lors du chargement du composant
    }, []);



    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };


    const showSuccessToast = () => {
        toast.success("Modification réussie");
    };
    const showErrorToast = () => {
        toast.error("Erreur d'envoie du fichier !");
    };
    const showErrorToastTcv = () => {
        toast.error("Le pub pour TCV n'est pas encore disponnible!");
    };
    const handleSelectChange = (
        newValue: MultiValue<Option>,
        actionMeta: ActionMeta<Option>
    ) => {
        setSelectedOptions(newValue);
    };



    //changer la valeur a afficher selon le srvice ex service ecp==> numero ecp
    const handleChangeOptionService = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setService(selectedValue.toLowerCase());

        if (selectedValue && selectedValue.trim() !== "") {
            // Filtrer les données en fonction de la base sélectionnée
            const filteredData = data.filter(item => item.base && item.base.toLowerCase() === selectedValue.toLowerCase());
            setFilteredData(filteredData);

            // Mettre à jour les options du Select en fonction des données filtrées
            const tempOptions: Option[] = filteredData.map(item => ({
                value: item.numero.toString(),
                label: item.numero.toString()
            }));
            setOptions(tempOptions);

            const filteredOptions = tempOptions.filter((option: Option) =>
                option.label.toLowerCase().includes(searchQuery?.toLowerCase() ?? "")
            );
            setFilteredOptions(filteredOptions);
        } else {
            // Si selectedValue est null ou vide, réinitialiser les données filtrées et les options
            setFilteredData([]);
            setOptions([]);
            setFilteredOptions([]);
        }
    };


    const handleChangeOptionCategorie = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setCategorie(selectedValue.toLowerCase());

    };


    function sleep(ms: any) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const handleUpload = () => {
        let urlEndPoint=''
        let categorieson= ''
        let concateson= ''
        if (service == 'ecp') {
            if (categorie == 'infoediteur') {
                urlEndPoint='/config/updateInfoEdEcpPlsNums';
                categorieson= 'INFOED'
                concateson= 'InfoEd_'

            } else if (categorie == 'accueil') {
                urlEndPoint='/config/updateAccueilEcp';
                categorieson= 'ACCUEIL'
                concateson= 'Accueil_'

            } else {
                urlEndPoint='/config/updatePubEcp';
                categorieson= 'PUB'
                concateson= 'Pub_'

            }

        } else if (service == 'tcv') {
            if (categorie == 'infoediteur') {
                urlEndPoint='/config/updateInfoEdPlsNumsTcv';
                categorieson= 'INFOED'
                concateson= 'InfoEd_'


            } else if (categorie == 'accueil') {
                urlEndPoint='/config/updateAccueilPlsNumsTcv';
                categorieson= 'ACCUEIL'
                concateson= 'Accueil_'

            } else {
                showErrorToastTcv()

            }
        } else if (service == '2rmusic') {
            if (categorie == 'infoediteur') {
                urlEndPoint='/config/updateInfoEdPlsNums2rMusic';
                categorieson= 'INFOED'
                concateson= 'InfoEd_'


            } else if (categorie == 'accueil') {
                urlEndPoint='/config/updateAccueilPslNumeros2rMusic';
                categorieson= 'ACCUEIL'
                concateson= 'Accueil_'

            } else {
                urlEndPoint='/config/updatePubPlsNums2rMusic';
                categorieson= 'PUB'
                concateson= 'Pub_'

            }
        }

        if (selectedFile) {

            let app_type = service.toLowerCase().toString();
            const formData = new FormData();
            formData.append('file', selectedFile);
            const token = getAuthToken();

            const selectedValues = (selectedOptions as Option[]).map(option => option.value);

            let numeroliste = selectedValues.join(',');

                // setIsLoading(true);
                axios.post('https://afone-global-config-back-master.dt-srv-194.ucorp.io/traitementFichier/uploadFile', formData, {
                    // Inclure le token d'authentification dans les en-têtes de la requête
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                })
                    .then(response => {
                        // Récupérer le nom du fichier retourné par le premier endpoint
                        const uploadedFileName = response.data.fileName;
                        const fullPath = "./uploads/" + uploadedFileName;

                        // Remplacez 'URL_DU_DEUXIEME_ENDPOINT' par l'URL réelle de votre deuxième endpoint POST
                        axios.get('https://afone-global-config-back-master.dt-srv-194.ucorp.io/traitementFichier/checkPlsNums', {

                            params: {
                                numeros: numeroliste,
                                file: fullPath,
                                regroupement: categorieson,

                            },


                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }
                        })
                            .then(response2 => {
                                const newname = uploadedFileName.replace(".wav", "");
                                // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                
                                
                                let endp=`https://afone-global-config-back-master.dt-srv-194.ucorp.io${urlEndPoint}`

                                if (categorie == "accueil") {
                                    axios.put(endp, null, {
                                     
                                        params: {
                                            numeros: numeroliste,
                                            newAccueil: concateson + newname,
                                        },
    
                                        headers: {
    
                                            'Authorization': `Bearer ${token}`,
                                        },
    
    
                                    })
    
                                        .then(response3 => {
                                            // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                            // setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                                            showSuccessToast();
                                            window.location.reload();
    
                                        }).catch(error2 => {
                                            showErrorToast()
                                            console.error(error2)
                                            // Gérer les erreurs du deuxième endpoint
                                            //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                                        });

                                } else if (categorie == "infoediteur") {
                                    axios.put(endp, null, {
                                     
                                        params:{
                                            numeros: numeroliste,
                                            newInfoEd: 'InfoEd_' + newname,
                                        },
    
                                        headers: {
    
                                            'Authorization': `Bearer ${token}`,
                                        },
    
    
                                    })
    
                                        .then(response3 => {
                                            // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                            // setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                                            showSuccessToast();
                                            window.location.reload();
    
                                        }).catch(error2 => {
                                            showErrorToast()
                                            console.error(error2)
                                            // Gérer les erreurs du deuxième endpoint
                                            //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                                        });

                                } else {
                                    axios.put(endp, null, {
                                     
                                        params:{
                                            numeros: numeroliste,
                                            newPub: 'Pub_' + newname,
                                        },
    
                                        headers: {
    
                                            'Authorization': `Bearer ${token}`,
                                        },
    
    
                                    })
    
                                        .then(response3 => {
                                            // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                            // setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                                            showSuccessToast();
                                            window.location.reload();
    
                                        }).catch(error2 => {
                                            showErrorToast()
                                            console.error(error2)
                                            // Gérer les erreurs du deuxième endpoint
                                            //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                                        });
                                }
                                
                                    
                            })
                            .catch(error3 => {
                                showErrorToast()
                                console.error(error3)
                                // Gérer les erreurs du deuxième endpoint
                                //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                            });

                    })
                    .catch(error => {
                        showErrorToast()
                        console.error(error)
                        // Gérer les erreurs du premier endpoint
                        window.alert('Erreur lors de l\'upload du fichier :' + error.message);
                    });

            
        }

    }



    // Style pour le choix multiples
    const colourStyles: CustomStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: 'white',
            borderRadius: '8px',
        }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? '#00BFFF' : isFocused ? '#87CEEB' : 'white',
                color: isSelected ? 'white' : 'black',
            };
        },
        // Ajoutez d'autres styles selon vos besoins
    };
    return (
        <>
            <div className="header-content">
                <Header />
            </div>
            <div className="container">
                <h1>Groupe de numéros</h1>
                <form>
                    <div className="d-flex flex-row mb-3" >
                        <div className="" style={{ paddingRight: '20px', }}>
                            <select className="form-select mb-3" style={{ width: '200px' }} onChange={handleChangeOptionService}>
                                <option selected>Choisir un service</option>
                                <option value="ecp">ECP</option>
                                <option value="tcv">TCV</option>
                                <option value="2rmusic">DIFF</option>
                            </select>
                        </div>
                        <div className="" >
                            <select className="form-select mb-3" style={{ width: '200px' }} onChange={handleChangeOptionCategorie}>
                                <option selected>categorie</option>
                                <option value="accueil">Accueil</option>
                                <option value="infoediteur">Infoediteur</option>
                                <option value="pub">Pub</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div className="input-group">
                            <input type="file" className="form-control" id="inputGroupFile04" onChange={handleFileChange} aria-describedby="inputGroupFileAddon04" aria-label="Upload" />
                        </div>
                    </div>
                    <br />
                    <div>
                        <label>Rechercher les numéros :</label>
                        <Select
                            isMulti
                            value={selectedOptions}
                            onChange={handleSelectChange}
                            options={filteredOptions}
                            styles={colourStyles}
                        />
                    </div><br />

                    <a className="btn btn-secondary btn-envoie" onClick={handleUpload}>
                        Envoyer
                    </a>
                    {isLoading ? (
                        <div className="loader left">
                            <PulseLoader color="#7B294D" size={40} loading={isLoading} />
                        </div>
                    ) : (
                        <ToastContainer />
                    )}
                </form>
            </div>
        </>
    );
};

export default MultipleNum;