import { useState, useEffect } from "react";
import axios from "axios";
import { getAuthToken, request } from '../helpers/axios_helper';
import { BarLoader } from "react-spinners";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


type EditesonInfoEditeurProps = {
    numero: string | null;
    base: string;
    path: string | null;
    infos: string | null;
};

const EditesonInfoEditeur: React.FC<EditesonInfoEditeurProps> = (props) => {
    const [typeson, setTypeson] = useState();
    const [uploaded, setUploaded] = useState(false);
    const [base, setbase] = useState(props.base);
    const [numero, setNumero] = useState(props.numero);
    const [path, setPath] = useState(props.path);
    const [info, setInfos] = useState(props.path);
    const [isLoading, setIsLoading] = useState(false);
    // const [nouveaunom, setNouveauNom] = useState("");

    useEffect(() => {
        setbase(props.base)
        setNumero(props.numero)
        setPath(props.path)

        const getinfo = props.infos

        if (getinfo == 'diff') {
            setInfos("diff")
        } else {
            setInfos("multidiff")

        }


    }, [props])

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };
    const showSuccessToast = () => {
        toast.success("Chargement du fichier réussie !");
    };

    const showErrorToast = () => {
        toast.error("Erreur d'envoie du fichier !");
    };

    const handleUpload = () => {
        if (selectedFile) {
            // Créez une instance de FormData pour envoyer le fichier au premier endpoint
            const formData = new FormData();
            formData.append('file', selectedFile);
            const token = getAuthToken();

            let app_type = base.toLowerCase().toString();



            if (app_type === 'ecp') {
                setIsLoading(true);

                // Remplacez 'URL_DU_ENDPOINT' par l'URL réelle de votre premier endpoint POST
                axios.post('https://afone-global-config-back-master.dt-srv-194.ucorp.io/traitementFichier/uploadFile', formData, {

                    // Inclure le token d'authentification dans les en-têtes de la requête
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                })

                    .then(response => {
                        // Récupérer le nom du fichier retourné par le premier endpoint
                        const uploadedFileName = response.data.fileName;
                        const fullPath = "./uploads/" + uploadedFileName;


                        // Remplacez 'URL_DU_DEUXIEME_ENDPOINT' par l'URL réelle de votre deuxième endpoint POST
                        axios.get('https://afone-global-config-back-master.dt-srv-194.ucorp.io/traitementFichier/check', {

                            params: {
                                numero: numero,
                                file: fullPath,
                                regroupement: 'INFOED',
                                outPutPath: path,

                            },


                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }
                        })
                            .then(response2 => {
                                const newname = uploadedFileName.replace(".wav", "");

                                // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                const data = {
                                    numero: numero,
                                    newInfoEd: 'InfoEd_' + newname,
                                };
                                axios.post("https://afone-global-config-back-master.dt-srv-194.ucorp.io/config/updateInfoEdEcp", data, {

                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    },

                                }).then(res => {
                                    // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                    setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                                    showSuccessToast();
                                    window.location.reload();
                                })
                                    .catch(error2 => {
                                        showErrorToast()
                                        console.log(error2)
                                        // Gérer les erreurs du deuxième endpoint
                                        //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                                    });
                            })
                            .catch(error4 => {
                                showErrorToast()
                                console.log(error4)
                                // Gérer les erreurs du deuxième endpoint
                                //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                            });
                    }
                    )
                    .catch(error => {
                        showErrorToast()
                        console.log(error)
                        // Gérer les erreurs du premier endpoint
                        window.alert('Erreur lors de l\'upload du fichier :' + error.message);
                    });

            }

            else if (app_type === 'tcv') {
                setIsLoading(true);

                axios.post('https://afone-global-config-back-master.dt-srv-194.ucorp.io/traitementFichier/uploadFile', formData, {
                    // Inclure le token d'authentification dans les en-têtes de la requête
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                })
                    .then(response => {
                        // Récupérer le nom du fichier retourné par le premier endpoint
                        const uploadedFileName = response.data.fileName;
                        const fullPath = "./uploads/" + uploadedFileName;


                        // Remplacez 'URL_DU_DEUXIEME_ENDPOINT' par l'URL réelle de votre deuxième endpoint POST
                        axios.get('https://afone-global-config-back-master.dt-srv-194.ucorp.io/traitementFichier/check', {

                            params: {
                                numero: numero,
                                file: fullPath,
                                regroupement: 'INFOED',
                                outPutPath: path,

                            },


                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }
                        })
                            .then(response2 => {
                                const newname = uploadedFileName.replace(".wav", "");

                                // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                const data = {
                                    numero: numero,
                                    newInfoEd: 'InfoEd_' + newname,
                                };
                                axios.post("https://afone-global-config-back-master.dt-srv-194.ucorp.io/config/updateInfoEdTcv", data, {



                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    },


                                })

                                    .then(response3 => {
                                        // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                        setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                                        showSuccessToast();
                                        window.location.reload();
                                    })
                                    .catch(error2 => {
                                        showErrorToast()
                                        console.log(error2)
                                        // Gérer les erreurs du deuxième endpoint
                                        //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                                    });
                            })
                            .catch(error3 => {
                                showErrorToast()
                                console.log(error3)
                                // Gérer les erreurs du deuxième endpoint
                                //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                            });

                    })
                    .catch(error => {
                        showErrorToast()
                        console.log(error)
                        // Gérer les erreurs du premier endpoint
                        window.alert('Erreur lors de l\'upload du fichier :' + error.message);
                    });

            }
            else if (app_type === '2rmusic') {
                setIsLoading(true);

                axios.post('https://afone-global-config-back-master.dt-srv-194.ucorp.io/traitementFichier/uploadFile', formData, {
                    // Inclure le token d'authentification dans les en-têtes de la requête
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                })
                    .then(response => {
                        // Récupérer le nom du fichier retourné par le premier endpoint
                        const uploadedFileName = response.data.fileName;
                        const fullPath = "./uploads/" + uploadedFileName;

                        // Remplacez 'URL_DU_DEUXIEME_ENDPOINT' par l'URL réelle de votre deuxième endpoint POST
                        axios.get('https://afone-global-config-back-master.dt-srv-194.ucorp.io/traitementFichier/check', {

                            params: {
                                numero: numero,
                                file: fullPath,
                                regroupement: 'INFOED',
                                outPutPath: path,

                            },

                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }
                        })
                            .then(response2 => {
                                const newname = uploadedFileName.replace(".wav", "");

                                // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                axios.put("https://afone-global-config-back-master.dt-srv-194.ucorp.io/config/updateInfoEd2rMusic", null, {

                                    params: {
                                        numero: numero,
                                        newInfoEd: 'InfoEd_' + newname,
                                        regroupement: info,
                                    },

                                    headers: {

                                        'Authorization': `Bearer ${token}`,
                                    },


                                })

                                    .then(response3 => {
                                        // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                        setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                                        showSuccessToast();
                                        window.location.reload();
                                    })
                                    .catch(error2 => {
                                        showErrorToast()
                                        console.log(error2)
                                        // Gérer les erreurs du deuxième endpoint
                                        //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                                    });
                            })
                            .catch(error3 => {
                                showErrorToast()
                                console.log(error3)
                                // Gérer les erreurs du deuxième endpoint
                                //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                            });

                    })
                    .catch(error => {
                        showErrorToast()
                        console.log(error)
                        // Gérer les erreurs du premier endpoint
                        window.alert('Erreur lors de l\'upload du fichier :' + error.message);
                    });

            }

        }
    };



    return (
        <>
            <div className="modal fade" id='editerSonEditeur' aria-hidden="true" aria-labelledby="exampleModalToggleLabel" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="float-end">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div><br />
                            <center>
                                <h1><b>Ajouter un son</b></h1>
                                <h6><b>Ajouter un son </b></h6><br />
                                <p>Sur quel(s) numéro(s) voulez-vous ajouter un son?</p>
                                <div className="row">
                                    <div className="float-start">
                                        <button className="btn m-5 btn-secondary" data-bs-target="#unNumeroEditeur" data-bs-toggle="modal">
                                            Seulement <br /> sur ce numéro
                                        </button>
                                    </div>
                                    <div className="float-end">
                                        <button className="btn m-5 btn-secondary" data-bs-target="#multiNum" data-bs-toggle="modal" disabled>
                                            Selectionner <br /> des numéros
                                        </button>
                                    </div>
                                </div>

                            </center>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="unNumeroEditeur" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <form action="">
                                <div className="float-end">
                                    <button type="button" id="btn-close-update" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div><br />
                                <center>
                                    <h2><b>Ajouter un son</b></h2>
                                    <h6 className="mb-5"><b>Sur InfoEditeur</b></h6><br />

                                    <div className="input-group" style={{ width: "50%" }}>
                                        <label htmlFor="audio-editeur" className="btn btn-light">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                            </svg>&nbsp; Selectionner un fichier
                                        </label>
                                        <br />
                                        <input type="file" id="audio-editeur" accept="audio/wav" style={{ display: "none" }} onChange={handleFileChange} />
                                    </div><br />
                                    <div className="">

                                        <a className="btn btn-secondary btn-envoie" onClick={handleUpload}>
                                            Envoyer
                                        </a>
                                        {isLoading ? (
                                            <div className="spinner">
                                                <BarLoader color={"white"} loading={isLoading} width={'100%'} />
                                            </div>
                                        ) : (
                                            <ToastContainer />
                                        )}
                                    </div>
                                </center>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="multiNum" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="float-end">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div><br />

                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">Back to first</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};



export { EditesonInfoEditeur }


