import axios, { AxiosRequestConfig } from 'axios';

export const getAuthToken = (): string | null => {
    return window.localStorage.getItem('auth_token');
};

export const setAuthToken = (token: string | null) => {
    if (token) {
        window.localStorage.setItem('auth_token', token);
    } else {
        window.localStorage.removeItem('auth_token');
    }
};

export const setUserName=(login_user: string | null)=>{
    if(login_user){
        localStorage.setItem("login",login_user);
    }else{
        localStorage.removeItem("login")
    }
}

export const getUserName=()=>{
    return localStorage.getItem("login")
}


export const setUserRole = (role: string | null) => {
    if (role) {
        window.localStorage.setItem('checkUser', role);
    } else {
        window.localStorage.removeItem('checkUser');
    }
};

export const getUserRole = (): string | null => {
    return window.localStorage.getItem('checkUser');
};

//PROD
axios.defaults.baseURL = 'https://afone-global-config-back-master.dt-srv-194.ucorp.io';

//LOCAL
// axios.defaults.baseURL = 'https://afone-global-config-back-master.dt-srv-194.ucorp.io';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const request = (method: string, url: string, data: any, headers?: AxiosRequestConfig['headers']): Promise<any> => {
    // Récupérer le token du local storage
    const token = getAuthToken();
    if (token) {
        // Inclure le token d'authentification dans les en-têtes de la requête si disponible
        if (!headers) {
            headers = {};
        }
        headers['Authorization'] = `Bearer ${token}`;
    }

    return axios({
        method: method,
        url: url,
        headers: headers,
        data: data,
    });
};
